<template>
  <section class="page-content">
    <h1>Om Hytta</h1>
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
      <p class="subtitle">
        Hytta er den digitale plattformen som forenkler bruken av hytta for deg, din familie, din utvidede familie og venner.
        Hytta.io vil samtidig stimulere til økt aktivitet i by og bygd – til gode for alle mindre samfunn rundt omkring Norges landstrakte kyst og vakre fjell – året rundt.
        <strong>Hytta.io er helt gratis og lar deg dele hytta med hvem du vil, når du vil og hvordan du vil.</strong>
      </p>
      </column>
    </row>
    <row container :gutter="20">
      <column :xs="12" :md="6" :lg="3">
        <div class="feature-card">
          <img alt="Sjekklister" src="../assets/who.png" />
          <h2>Hvem</h2>
          <p>Inviter familie, venner eller leietakere som brukere</p>
          <br>
        </div>
      </column>
      <column :xs="12" :md="6" :lg="3">
        <div class="feature-card">
          <img alt="Sjekklister" src="../assets/when.png" />
          <h2>Når</h2>
          <p>Planlegg når hytta brukes hvem med vår hytte-kalender</p>
          <br>
        </div>
      </column>
      <column :xs="12" :md="6" :lg="3">
        <div class="feature-card">
          <img alt="Sjekklister" src="../assets/how.png" />
          <h2>Hvordan</h2>
          <p>Kontroller bruken- og ta vare på hytta ved å lage sjekklister som brukerne skal følge.</p>
        </div>
      </column>
      <column :xs="12" :md="6" :lg="3">
        <div class="feature-card">
          <img alt="Sjekklister" src="../assets/why.png" />
          <h2>Hvorfor</h2>
          <p>Du har Hytta til enhver tid med deg i lomma.</p>
          <br>
        </div>
      </column>
    </row>
  </section>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
  .feature-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: $light-grey;
    border-radius: 14px;
    padding: 1.5rem;
    img {
      width: 50%;
      margin-bottom: 1rem;
    }
    h2 {
      font-weight: 500;
    }
  }
</style>
